/*jshint esversion: 6 */

import AppAlert from './Alert.vue';

const Alert = {
  install(Vue) {
    this.EventBus = new Vue();
    
    Vue.component('alert', AppAlert);

    Vue.prototype.$alert = {
      show(params) {
        Alert.EventBus.$emit('show', params);
      }
    };
  }
};

export default Alert;